import './styles/app.scss';

import $ from 'jquery';

import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui-dist/jquery-ui.css';

// Import Chart.js and the necessary geo plugins
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
window.Chart = Chart;

window.Chart.defaults.font = {
    family: 'Frutiger LT Std',
    weight: '500',
    size: 13, // Default font size
    style: 'normal', // Default font style
    lineHeight: 1.2, // Line height
};



import * as d3 from "d3";

// Define the initial dimensions
const width = 800, height = 600;
const colorScale = d3.scaleSequential(d3.interpolateBlues).domain([0, 100]);
const projection = d3.geoEqualEarth().scale(160).translate([width / 2, height / 2]);
const path = d3.geoPath().projection(projection);

function initWorldMap(containerId, geoJsonUrl, dataVector) {
    const container = d3.select(`#${containerId}`);
    const svg = container.append("svg")
        .attr("viewBox", `0 0 ${width} ${height}`) // Define the viewBox for responsiveness
        .attr("preserveAspectRatio", "xMidYMid meet") // Maintain aspect ratio
        .style("width", "100%") // Scale the width responsively
        .style("height", "auto"); // Let the height adjust dynamically

    d3.json(geoJsonUrl).then(geoData => {
        console.log("GeoJSON loaded:", geoData);
        console.log("Data Vector:", dataVector);

        svg.selectAll("path")
            .data(geoData.features)
            .enter().append("path")
            .attr("d", path)
            .attr("fill", d => {
                const countryCode = d.properties.adm0_a3;
                const value = dataVector[countryCode] || 0;
                return colorScale(value);
            })
            .attr("stroke", "#000")
            .attr("stroke-width", 0.5);
    }).catch(error => console.error("Error loading GeoJSON:", error));
}

// ✅ Expose the function to the global window object for usage in Twig
window.initWorldMap = initWorldMap;















import DataTable from 'datatables.net-dt';

function initializeDataTable(selector) {
    return new DataTable(selector, {
        'autoWidth': false ,
        'responsive': true ,
        //'scrollX': true ,
        'stateSave': false,
        'paging': false,
        'info': false,
        language: {
            search: '',
            searchPlaceholder: 'Filter'
        }
    });
}

initializeDataTable('.DataTable');

$(document).on('click', 'a.toggable', function (e) {

    $(this).parents('section').removeClass('is-toggable-section');

});

$(document).on('click', '.js-repeatable .js-btn-add', function (e) {

    var to = $(this).parents('.js-repeatable');

    var clone = to.clone();
    clone.find('input[type="text"]').val('');
    clone.insertAfter(to);

});

$(document).on('click', '.js-repeatable .js-btn-remove', function (e) {

    $(this).parents('.js-repeatable').remove();

});

/**
 * Runs through each Order row and adds a class that display-blocks the Show Grouped Orders button.
 */
if ($('tr[data-order-id]').is('*')) {


    $('tr[data-order-id]').each(function () {

        var order_id = $(this).data('order-id');

        $('tr[data-parent-order-id="' + order_id + '"]').addClass('has-grouped-orders');

    });

}

$(document).on('click', '[js-toggle-groupedorders]', function () {

    let dataTable = $(this).closest('table').DataTable(); // Get the DataTable instance of the closest parent table

    var row = $(this).parents('tr');
    var order_id = row.data('parent-order-id');

    console.log($(this));
    console.log(row);
    console.log(order_id);

    //var parent_row = $('tr[data-parent-order-id="' + order_id + '"]');

    var rows = $('tr[data-order-id="' + order_id + '"]');

    $(this).toggleClass('active');

    if ($(this).hasClass('active')) {

        dataTable.search(order_id).draw();
        rows.addClass('is-visible');
        console.log('active');

        $(this).find('i').removeClass('fa-arrows-from-line').addClass('fa-arrows-to-line');

        /*
        $('tr[data-parent-order-id="'+oid+'"] td').css({
            'visibility': 'visible' ,
            'height': '100%;' ,
            'line-height': '1.5' ,
            'padding' : '0.33rem'
        });
        */

    } else {

        dataTable.search('').draw();
        rows.removeClass('is-visible');
        console.log('in-active');

        $(this).find('i').removeClass('fa-arrows-to-line').addClass('fa-arrows-from-line');

        /*
        $('tr[data-parent-order-id="'+oid+'"] td').css({
            'visibility': 'hidden' ,
            'height': '0' ,
            'line-height': '0' ,
            'padding' : '0'
        });
        */

    }

});

$('.modal').each(function () {

    if ($(this).data('toggle-modal')) {

        $(this).addClass('active');
        $('body').addClass('modal-active');

    }

});

$(document).on('click', '[js-hide-modal]', function () {

    $(this).parents('.modal').removeClass('active');

    $('body').removeClass('modal-active');

});


$(document).ready(function () {

    $('.hu-ve-vg-filter button').on('click', function () {

        const selectedValue = null;
        const selectName = $(this).attr('name');

        $.ajax({
            url: '/async/set-filter', // TODO duplicate of the select below
            method: 'POST',
            data: {
                select: selectName,
                value: selectedValue
            },
            success: function (response) {

                if (response.status === 'success') {
                    window.location.reload();
                }
            }
        });

    });

    // Event handler for select change
    $('.hu-ve-vg-filter select').on('change', function () {
        // Get the selected value
        const selectedValue = $(this).val();
        const selectName = $(this).attr('name'); // e.g., 'hubs', 'vessels', 'vesselgroups'

        // Send the selected value to the server via AJAX
        $.ajax({
            url: '/async/set-filter', // Your Symfony route that will handle the session update
            method: 'POST',
            data: {
                select: selectName,
                value: selectedValue
            },
            success: function (response) {

                if (response.status === 'success') {
                    window.location.reload();
                }
            }
        });
    });
});

$(document).ready(function() {
    let hideTimeout;

    // Show submenu when the parent is hovered
    $('nav.primary > ul li.has-menu-items').on('mouseenter', function() {
        clearTimeout(hideTimeout);  // Cancel any scheduled hiding
        $(this).children('ul').stop(true, true).fadeIn(200);  // Show the submenu
    });

    // When leaving the parent, delay the hiding of the submenu
    $('nav.primary > ul li.has-menu-items').on('mouseleave', function() {
        let $submenu = $(this).children('ul');

        hideTimeout = setTimeout(function() {
            $submenu.stop(true, true).fadeOut(200);  // Hide after delay
        }, 200);  // Adjust delay as needed (500ms here)
    });

    // If the mouse enters the submenu, cancel the hiding delay
    $('nav.primary > ul li.has-menu-items > ul').on('mouseenter', function() {
        //clearTimeout(hideTimeout);  // Cancel hiding when submenu is hovered
    });


});

$(document).ready(function() {

    function loadContent(element , formData = null) {

        let contentDiv = element; // Select the div with the data-path attribute
        let dataPath = contentDiv.data('path'); // Get the URL from the data-path attribute

        let method = 'GET';
        if (formData) {
            method = 'POST'
        }

        // Perform an AJAX request to load the content
        $.ajax({
            url: dataPath,
            method: method,
            data: formData,
            success: function(response) {
                // Replace the content of the div with the response

                contentDiv.html(response);
                attachFormSubmitHandler(); // Re-attach form submission handler

                if (element.find('.DataTable')) {

                    initializeDataTable(element.find('.DataTable'));
                }

            },
            error: function() {
                contentDiv.html('<section class="hidden"><i class="fa-light fa-triangle-exclamation text-red-800 mr-2"></i><span class="text-red-800 text-xs">Sorry, there was an error loading the content.</span></section>');
            }
        });
    }

    $('[data-path]').each(function () {

        loadContent($(this));

    });

    // Attach form submission handler for dynamic content
    function attachFormSubmitHandler() {
        $('[data-id="csg"] input[type="radio"]').on('change', function(e) {
            e.preventDefault(); // Prevent default form submission

            let form = $(this).parents('form');
            let formData = form.serialize(); // Serialize the form data

            loadContent($('[data-id="csg"]') , formData);

        });
    }
});

$(document).ready(function () {

    $.widget( "custom.autocomplete", $.ui.autocomplete, {
        _renderItem: function( ul, item ) {
            var badge = ''; // Create an empty badge

            if (item.type === 'order') {
                badge = '<span class="badge badge-order">Order</span>';
            } else if (item.type === 'dispatch') {
                badge = '<span class="badge badge-dispatch">Dispatch</span>';
            }

            return $("<li>")
                .append('<div>' + item.value + ' ' + badge + '</div>') // Append the name and the badge
                .appendTo(ul);
        }
    });

    $("#quicksearch").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/async/quicksearch/",
                data: {
                    term: request.term // The input value
                },
                success: function (data) {
                    response(data); // Assuming 'data' is an array of suggestions
                }
            });
        },
        minLength: 3, // Minimum characters to start the autocomplete
        select: function (event, ui) {
            var orderId = ui.item.order_id; // Get the order_id from the selected item

            // Redirect to the order view page
            if (orderId) {
                window.location.href = "/order/" + orderId;
            }
        }
    });

});

$(document).ready(function() {
    function updateClock() {
        // Define a mapping of classes to time zones and their CEST offsets
        var timezones = {
            'js-rotterdam': { timeZone: 'Europe/Amsterdam', label: 'CEST' },     // Rotterdam is CEST
            'js-houston': { timeZone: 'America/Chicago', label: 'CEST-7' },      // Houston is 7 hours behind CEST
            'js-singapore': { timeZone: 'Asia/Singapore', label: 'CEST+6' },     // Singapore is 6 hours ahead of CEST
            'js-osaka': { timeZone: 'Asia/Tokyo', label: 'CEST+7' }              // Osaka is 7 hours ahead of CEST
        };

        // Loop through each class and update the corresponding clock
        $.each(timezones, function(className, timezoneData) {
            $('.' + className).each(function() {
                var $clock = $(this);
                var now = new Date();

                var options = {
                    timeZone: timezoneData.timeZone,  // Use the corresponding time zone from the map
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false  // 24-hour format
                };

                // Format the time in HH:MM
                var timeString = now.toLocaleTimeString('en-GB', options);

                // Display the time with the appropriate CEST offset
                $clock.text(timeString + ' (' + timezoneData.label + ')');
            });
        });
    }

    // Update the clock every second
    setInterval(updateClock, 1000);

    // Run once to avoid delay
    updateClock();
});

if ($('body').hasClass('warehouses')) {

    function updateContent(hash) {
        // If there is a hash in the URL

        console.log(hash);

        if (hash) {

            $('div[data-id]').removeClass('block').addClass('hidden');
            $('div[data-id="' + hash + '"]').addClass('block').removeClass('hidden');


            $('nav.js-warehouses-nav ul li').removeClass('font-bold');
            $('nav.js-warehouses-nav a[href="#' + hash + '"]').parent('li').addClass('font-bold');
        }
    }

    // Handle clicks on nav a elements
    $('nav.js-warehouses-nav a').click(function() {
        var hash = $(this).attr('href').substring(1); // Remove the '#' from the href
        updateContent(hash);
        // Update the URL hash
        location.hash = hash;
        return false; // Prevent default link behavior
    });

    // Check if there is a hash in the URL on load and update content
    if (window.location.hash) {
        updateContent(window.location.hash.substring(1));
    }

    // Ensure content is updated when the hash changes in the URL
    $(window).on('hashchange', function() {
        updateContent(window.location.hash.substring(1));
    });

}

$(document).ready(function () {

    // Check if the user has dark mode enabled on their device
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        $('html').addClass('dark');
        $('#darkmode').prop('checked', true); // Update the toggle button if necessary
    }

    // Toggle dark mode on checkbox click
    $('#darkmode').on('change', function () {
        if ($(this).is(':checked')) {
            $('html').addClass('dark'); // Add 'dark' class to <html> for Tailwind dark mode
            localStorage.setItem('theme', 'dark'); // Store user preference in localStorage
        } else {
            $('html').removeClass('dark'); // Remove 'dark' class from <html>
            localStorage.setItem('theme', 'light'); // Store user preference in localStorage
        }
    });

    // Check for stored theme preference in localStorage
    if (localStorage.getItem('theme') === 'dark') {
        $('html').addClass('dark');
        $('#darkmode').prop('checked', true);
    } else if (localStorage.getItem('theme') === 'light') {
        $('html').removeClass('dark');
        $('#darkmode').prop('checked', false);
    }
});

/**
 * NAV TOGGLE
 */

document.addEventListener('DOMContentLoaded', function() {
    // Select the .nav-toggle element
    var navToggle = document.querySelector('.is-nav-toggle');

    // Check if the navToggle element exists to avoid errors
    if (navToggle) {
        // Add click event listener to the .nav-toggle element
        navToggle.addEventListener('click', function() {
            // Toggle the 'active' class on the .nav-toggle element itself
            this.classList.toggle('active');

            // Toggle the 'nav-active' class on the body tag
            document.body.classList.toggle('nav-active');
        });
    }
});

$(document).ready(function () {
    // Function to show the tooltip on hover
    $('[js-tooltip]').hover(function () {
        const tooltipText = $(this).attr('js-tooltip');
        const orderId = $(this).data('ordertooltip-target');

        // Hide any other visible tooltips or divs
        $('.js-tooltip').removeClass('active');

        // Reset tooltip text and position
        const tooltip = $('.js-tooltip');
        tooltip.text(tooltipText).addClass('active');

        // Positioning the tooltip above the link
        const position = $(this).offset();
        tooltip.css({
            top: position.top - tooltip.outerHeight() - 10 + 'px', // 10px above the element
            left: position.left + 'px'
        });

    }, function () {
        // Hide the tooltip when not hovering anymore
        $('.js-tooltip').removeClass('active');
    });

    // Function to show/hide the detailed div on click
    $('[js-tooltip]').on('click', function (e) {
        e.preventDefault(); // Prevent the default anchor behavior

        const orderId = $(this).data('ordertooltip-target');
        const orderDetailDiv = $(`div[data-ordertooltip-id="${orderId}"]`);

        // Hide any other active tooltips or details
        $('[js-tooltip]').removeClass('active');
        $('.js-tooltip').removeClass('active');
        $('.js-tooltip-detail').removeClass('active');

        $(this).addClass('active');

        // Position the tooltip detail above the element
        const position = $(this).offset();
        orderDetailDiv.css({
            top: position.top - orderDetailDiv.outerHeight() - 10 + 'px', // 10px above the element
            left: position.left + 'px'
        });

        // Show the tooltip detail
        orderDetailDiv.addClass('active').fadeIn();
    });

    // Function to close the detailed div when clicking the close button
    $(document).on('click', '.js-tooltip-detail .close', function () {

        $(this).parents('td').find('[js-tooltip]').removeClass('active');
        $(this).closest('.js-tooltip-detail').removeClass('active');

    });
});

$(document).on('click', '[js-copy-data]', function (e) {
    var text = $(this).attr('js-copy-data');

    console.log(text);

    // Use the modern Clipboard API to copy the text
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(function() {
            console.log('Text copied to clipboard');
        }).catch(function(err) {
            console.error('Failed to copy text: ', err);
        });
    } else {
        // Fallback for older browsers that don't support the Clipboard API
        var $tempInput = $('<input>');
        $('body').append($tempInput);
        $tempInput.val(text).select();
        document.execCommand('copy');
        $tempInput.remove();
    }
});

$(document).on('click', 'body.log-in input[name="agree"]', function () {

    if ( $(this).is(':checked') ) {

        $('button[type="submit"]').prop('disabled','');

    } else {

        $('button[type="submit"]').prop('disabled','disabled');

    }

});

/**
 * TABS
 */
document.addEventListener('DOMContentLoaded', function () {
    // Initialize tabs for a given container
    function initializeTabs(tabsContainer) {
        let ul = tabsContainer.querySelector('nav ul');

        function tabToggle(tab, index) {
            let li = document.createElement('li');
            let a = document.createElement('a');

            // Get the tab name and encode it for use in the URL
            let tabName = tab.getAttribute('data-tab-name');
            let encodedTabName = encodeURIComponent(tabName);

            a.textContent = tabName;
            a.href = `#${encodedTabName}`;

            const dataClass = tab.getAttribute('data-class');
            if (dataClass) {
                a.classList.add(dataClass);
            }

            a.addEventListener('click', function (event) {
                event.preventDefault();

                // Update the URL hash without scrolling
                history.pushState(null, null, a.href);

                activateTabByHash();
            });

            if (index === 0) {
                li.classList.add('active');
            }

            li.appendChild(a);
            ul.appendChild(li);
        }

        // Function to activate a tab based on the current URL hash
        function activateTabByHash() {
            let hash = decodeURIComponent(window.location.hash.substring(1)); // Decode the hash
            let matchedTab = tabsContainer.querySelector(`.tab[data-tab-name="${hash}"]`);
            let matchedLink = ul.querySelector(`a[href="#${encodeURIComponent(hash)}"]`);

            // Reset all tabs and links
            tabsContainer.querySelectorAll('.tab').forEach(t => t.classList.remove('active'));
            ul.querySelectorAll('li').forEach(li => li.classList.remove('active'));

            if (matchedTab && matchedLink) {
                matchedTab.classList.add('active');
                matchedLink.parentElement.classList.add('active');
            } else {
                // Default to the first tab if no match is found
                tabsContainer.querySelector('.tab').classList.add('active');
                ul.querySelector('li').classList.add('active');
            }
        }

        // Initialize each tab within the container
        tabsContainer.querySelectorAll(':scope > .tab').forEach(function (tab, index) {
            tabToggle(tab, index);
        });

        // Activate tab based on the current hash when initializing
        activateTabByHash();

        // Handle hash change events (e.g., back/forward navigation)
        window.addEventListener('hashchange', activateTabByHash);
    }

    // Find and initialize all top-level .tabs containers
    document.querySelectorAll('.tabs').forEach(function (tabsContainer) {
        initializeTabs(tabsContainer);
    });
});